@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


section {
    height: 100vh;
    color: var(--font-color-1)
    /* scroll-snap-align: start; */
}


.sectionContainer {
    position: relative;
    top: -1vh;
    z-index:  100;

}


#section1 {

    /* background-color: var(--secondary-color);
     */
     background-color: #090c0c;
background-image: linear-gradient(180deg, #090c0c 8%, #85ffbd 75%, #090c0c 80%);




    border-width: thick;
    display: flex;
    justify-content: center;

    /* height: 75vh; */
    margin: auto;

}
.section1-inner-container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.section1-inner-container.loaded {
    opacity: 1;
}

.section1-animate {
    transition: transform 0.5s ease-in-out;
    color: var(--font-color-1);
}

.section1-inner-container.loaded .section1-animate {
    transform: translateY(-20px);
} 




#section1-img{
    object-fit: contain;
}

.section1-logo-container{
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section1-logo-outer{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#section1-inner-text{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

}

#section1-text-header{
    color:var(--font-color-1);
    font-size: 3em;
}

.animatedtext {
    margin-top: 50px;
    line-height: 1.5em;

}

.animatedtext p{
    margin-top: 25px;
}
/* Particle Background starts */

/* Particle Background ends */

/* making the typing animation */





/* making the typing animation */




#section1 button {
    background-color: white;
    border: none;

    width: 32%;
    height: 15%;
    border-radius: 25px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
    margin: auto;
    margin-top: 30px;
    position: relative;
}

#section1 button:hover {
    letter-spacing: 2px;

}



.IntroductionText {
    /* background-color: var(--secondary-color); */
    color: var(--font-color-1);
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    /* position: absolute; */
    padding: 2em;
    height: 400px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.IntroductionText h1 {
    font-size: 60px;
    color: var(--tertiary-color);
    font-weight: 1000;
    font-family: 'Rubik', sans-serif;
    font-size: 200px;
}

.IntroductionText p {
    font-size: 2rem;
    font-family: 'Poppins' , 'sans-serif';
}

/* 
.IntroductionImage {
    background-color: #12141d;
    width: 18%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */


/* section 2 starts */
#section2 {
    background-color: var(--primary-color);
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
   
}


.workContainer{
    position: absolute;
    border-radius: 0.2em;
    color: white;
    width: 100%;
    
}


.workContainerTitle{
    font-size: 4em;
    font-weight: 900;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}
.workInnerContainer{
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;  /* For Firefox */
}

.workInnerContainer::-webkit-scrollbar {
    display: none;
 }
 














/* section 2 ends */

#section3 {

    background-color: #12141d;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.messageOuterContainer{
    background-color: rgba(255, 255, 255, .07);  
    border-radius: 0.2em;
    backdrop-filter: blur(5px);
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 3s;
    /* transform-style: preserve-3d; */
    position: absolute  ;

}


/* Front Card  */

.frontCard {
    background-color: var(--tertiary-color); 
    font-size: 3rem;  
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    font-family: 'Shadows Into Light', cursive;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    /* position: absolute; */
    transition: 3s;
    width: 70%;
    height: 70%;
    /* transform-style: preserve-3d;     */
    /* backface-visibility: hidden; */
    z-index: 10;
       
    
}


.cardAnime{
    animation: cardanime 2s forwards;

}

@keyframes cardanime {
    100%{
        transform: translateX(-100%);
    }
    0%{
        transform: translateX(0);
    }
}


ion-icon {
    font-size: 150px;
    font-family: 'Shadows Into Light', cursive;
  }

.clickContact {
    font-size: 1rem;
    margin-top: 100px;
    cursor:pointer;
}
.clickContact ion-icon {
    font-size: 1rem;   
}




  /* Front Card ends */

.messageText{

    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3em;
    color: whitesmoke;
    padding-left:0.7em;
}
.messageText h2{
    color: greenyellow;
}

.messageText p {
    font-size: 0.4em;
}
.messageForm{

    width: 40%;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 

}

.formOuter{
    width: 80%;
    height: 80%;
    /* background-color: turquoise; */
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
}

.partitionLine {
    background-color: var(--tertiary-color);
    width: 5px;
    height: 60%;
}

.formInput{
    width: 100%;
    height: 40px;
    margin: 2%;
    border:none;
    padding-right: 30px;
    background-color: transparent;
    border-bottom: 2px solid grey;
    text-decoration-style: none;
    caret-color: whitesmoke;
    color: white;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px;
    
    font-weight: 500;

    
}

.formInput::placeholder {
    color: whitesmoke;
    opacity: 0.5;
    padding-left: 10px;
}

.formInput2{
    width: 100%;
    margin: 2%;
    padding-left: 10px;
    border:none;
    padding-right: 30px;
    text-decoration: none;
    border-style: none;
    background-color: transparent;
    border-bottom: 2px solid grey ;
    caret-color: whitesmoke;
    color:white;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    flex-grow: 2;
    margin-bottom: 20px;
    font-weight: 500;
}

.formButton{

    width: 40%;
    height: 45px;
    background-color: greenyellow;
    margin: 2%;
    border:none;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    border-style: none;
    cursor: pointer;

}
textarea:focus, input:focus{
    outline: none;
}
.responsiveContact{
    display: none;
}

/* Screen 1 */

@media screen  and (max-width: 920px) {

    .messageOuterContainer{
        width: 85%;
    }

    .messageText {
        display: none;
    }
    
    .messageForm {
        width: 100%;
        
    }
    .partitionLine {
        display: none;
    }
    .responsiveContact{
        display: block;
        font-family: "poppins" , "sans-serif";
        font-size: 2rem;
    }
    
}

/* Screen 2 */

@media screen  and (max-width: 600px) {

    .workInnerContainer{
        justify-content: start;
        padding-left: 5px;
    }

    .workContainerTitle {
        justify-content: start;
        padding-left: 50px;
        font-size: 50px;
    }
    #section1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150vh;
    }
    .section1-inner-container{
        width: 100%;
    }
}



@media screen  and (max-width: 360px) {

    .workInnerContainer{
        justify-content: start;
        padding-left: 5px;
    }

    .workContainerTitle {
        justify-content: start;
        padding-left:30px;
        font-size: 30px;
        margin-bottom: 0px;

    }
    .frontCard {

        font-size: 30px;
    }

    ion-icon {
        font-size: 75px;
        font-family: 'Shadows Into Light', cursive;
      }


}

