img{
    width: 80%;
    height: 80%;
}

.imageContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10%;
    height: 80vh;
    color: aliceblue;
    letter-spacing: 0.5ch;
    padding: 20px;
    text-align: center;
}

a {
    cursor: pointer;
}


@media screen  and (max-width: 500px) {

    img{
        width: 95%;
        height: 80%;
    }
    
    
  
  }
  
  @media screen  and (max-width: 360px) {
    img{
        width: 99%;
        height: 80%;
    }
    

   
  }
  
  @media screen  and (max-width: 280px) {

    img{
        width: 99%;
        height: 80%;
    }
    
   
  }