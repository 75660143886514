@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Allison&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Ubuntu:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=ZCOOL+KuaiLe&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

graphicsectionContainer {
  background-color: var(--secondary-color);
  z-index: 10;
  max-height: 400px;
}

.mainPage{
  -webkit-animation: inflow 2s linear -1.5s;
          animation: inflow 2s linear -1.5s;
}

/* @keyframes inflow {
  from {
    transform: translateX(+100%);

  }
  to {
    transform: translateX(0)
  }
} */
:root {
    --primary-color: #090C0C;
    --secondary-color: #12141D;
    --tertiary-color: #87B913;
    --font-color-1: #ffffff;
    --fontsize-sm: 10px;
    --fontsize-md: 15px;
    --fontsize-lg: 25px;
    --fontsize-xl: 40px;

  }


.navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #090C0C;
    background-color: var(--primary-color);
    height: 12vh;
    color: #ffffff;
    color: var(--font-color-1);
    letter-spacing: 3px;

    z-index: 1000;
    position: -webkit-sticky;
    position: sticky;
    top:0px;




}


.navTitle {
    font-family: 'Poppins', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 35px;
    padding-left: 30px;

}

.navItems {
    display: flex;
    justify-content: space-around;
    width: 40%;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 2px;
    /* font-weight: bolder; */


}

.navItem {
    list-style: none;
}

a {
    text-decoration: none;
    color: #ffffff;
    color: var(--font-color-1);
    font-family: 'Poppins', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 1.5rem;
    /* font-family: 'Ubuntu', sans-serif; */
}

#overlay {
    display: none;
}



@media (max-width: 920px) {

    .navItems{
        display: none;
    }
    #hamburger {
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.25s linear;
        width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
        height: clamp(2rem, 1.4vw + 1.7rem, 5rem);
        display: flex;
        align-items: center;
        padding-right: 4rem;
      }
      
      #hamburger:hover {
        opacity: 1;
      }
      
      #hamburger div,
      #hamburger div:after,
      #hamburger div:before {
        background-color: #fafafa;
        border-radius: 10px;
        width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
        height: clamp(0.2rem, 0.3vw + 0.1rem, 0.8rem);
        transition: all 0.15s linear;
      }
      
      #hamburger div:before,
      #hamburger div:after {
        content: "";
        position: absolute;
      }
      
      #hamburger div:before {
        -webkit-transform: translateY(-200%);
                transform: translateY(-200%);
      }
      
      #hamburger div:after {
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
      }
      
      #hamburger.open div {
        background: transparent;
      }
      
      #hamburger.open div:before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
      }
      
      #hamburger.open div:after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
      }
      
      #overlay {
        position: absolute;
        width: 100%;
        min-height: 100%;
        background-color: #090C0C;
        background-color: var(--primary-color);
        z-index: 10;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
        transition: all 0.5s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      #overlay.menu {
        -webkit-transform: translateY(65%);
                transform: translateY(65%);
      }
      
      #overlay a {
        text-decoration: none;
        color: #fafafa;
        font-size: clamp(1rem, 4.1vw + 0.2rem, 10rem);
        margin: 2%;
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;
      }
      
      #overlay a:hover {
        opacity: 1;
      }

        .navTitle {
            font-size: 30px;
        }
        


}

@media screen  and (max-width: 500px) {
  .navTitle{
    font-size: 25px;
  }

}

@media screen  and (max-width: 360px) {

  .navTitle{
    font-size: 20px;
    font-weight: bold;
    font-family: "poppins" , "sans-serif";

  }

  #overlay.menu {
    -webkit-transform: translateY(90%);
            transform: translateY(90%);
  }





 
}

@media screen  and (max-width: 280px) {

  .navTitle{
    font-size: 20px;
    font-weight: bold;
    font-family: "poppins" , "sans-serif";

  }

  #overlay.menu {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  #overlay a {
    margin: 40px;
  }
  #overlay{
    height: 500px;
  }
 
}
img{
    width: 80%;
    height: 80%;
}

.imageContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10%;
    height: 80vh;
    color: aliceblue;
    letter-spacing: 0.5ch;
    padding: 20px;
    text-align: center;
}

a {
    cursor: pointer;
}


@media screen  and (max-width: 500px) {

    img{
        width: 95%;
        height: 80%;
    }
    
    
  
  }
  
  @media screen  and (max-width: 360px) {
    img{
        width: 99%;
        height: 80%;
    }
    

   
  }
  
  @media screen  and (max-width: 280px) {

    img{
        width: 99%;
        height: 80%;
    }
    
   
  }
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


section {
    height: 100vh;
    color: var(--font-color-1)
    /* scroll-snap-align: start; */
}


.sectionContainer {
    position: relative;
    top: -1vh;
    z-index:  100;

}


#section1 {

    /* background-color: var(--secondary-color);
     */
     background-color: #090c0c;
background-image: linear-gradient(180deg, #090c0c 8%, #85ffbd 75%, #090c0c 80%);




    border-width: thick;
    display: flex;
    justify-content: center;

    /* height: 75vh; */
    margin: auto;

}
.section1-inner-container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.section1-inner-container.loaded {
    opacity: 1;
}

.section1-animate {
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    color: var(--font-color-1);
}

.section1-inner-container.loaded .section1-animate {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
} 




#section1-img{
    object-fit: contain;
}

.section1-logo-container{
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section1-logo-outer{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

#section1-inner-text{
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;

}

#section1-text-header{
    color:var(--font-color-1);
    font-size: 3em;
}

.animatedtext {
    margin-top: 50px;
    line-height: 1.5em;

}

.animatedtext p{
    margin-top: 25px;
}
/* Particle Background starts */

/* Particle Background ends */

/* making the typing animation */





/* making the typing animation */




#section1 button {
    background-color: white;
    border: none;

    width: 32%;
    height: 15%;
    border-radius: 25px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
    margin: auto;
    margin-top: 30px;
    position: relative;
}

#section1 button:hover {
    letter-spacing: 2px;

}



.IntroductionText {
    /* background-color: var(--secondary-color); */
    color: var(--font-color-1);
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    /* position: absolute; */
    padding: 2em;
    height: 400px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.IntroductionText h1 {
    font-size: 60px;
    color: var(--tertiary-color);
    font-weight: 1000;
    font-family: 'Rubik', sans-serif;
    font-size: 200px;
}

.IntroductionText p {
    font-size: 2rem;
    font-family: 'Poppins' , 'sans-serif';
}

/* 
.IntroductionImage {
    background-color: #12141d;
    width: 18%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */


/* section 2 starts */
#section2 {
    background-color: var(--primary-color);
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
   
}


.workContainer{
    position: absolute;
    border-radius: 0.2em;
    color: white;
    width: 100%;
    
}


.workContainerTitle{
    font-size: 4em;
    font-weight: 900;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}
.workInnerContainer{
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;  /* For Firefox */
}

.workInnerContainer::-webkit-scrollbar {
    display: none;
 }
 














/* section 2 ends */

#section3 {

    background-color: #12141d;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.messageOuterContainer{
    background-color: rgba(255, 255, 255, .07);  
    border-radius: 0.2em;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 3s;
    /* transform-style: preserve-3d; */
    position: absolute  ;

}


/* Front Card  */

.frontCard {
    background-color: var(--tertiary-color); 
    font-size: 3rem;  
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    font-family: 'Shadows Into Light', cursive;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    /* position: absolute; */
    transition: 3s;
    width: 70%;
    height: 70%;
    /* transform-style: preserve-3d;     */
    /* backface-visibility: hidden; */
    z-index: 10;
       
    
}


.cardAnime{
    -webkit-animation: cardanime 2s forwards;
            animation: cardanime 2s forwards;

}

@-webkit-keyframes cardanime {
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
    0%{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes cardanime {
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
    0%{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}


ion-icon {
    font-size: 150px;
    font-family: 'Shadows Into Light', cursive;
  }

.clickContact {
    font-size: 1rem;
    margin-top: 100px;
    cursor:pointer;
}
.clickContact ion-icon {
    font-size: 1rem;   
}




  /* Front Card ends */

.messageText{

    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3em;
    color: whitesmoke;
    padding-left:0.7em;
}
.messageText h2{
    color: greenyellow;
}

.messageText p {
    font-size: 0.4em;
}
.messageForm{

    width: 40%;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 

}

.formOuter{
    width: 80%;
    height: 80%;
    /* background-color: turquoise; */
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
}

.partitionLine {
    background-color: var(--tertiary-color);
    width: 5px;
    height: 60%;
}

.formInput{
    width: 100%;
    height: 40px;
    margin: 2%;
    border:none;
    padding-right: 30px;
    background-color: transparent;
    border-bottom: 2px solid grey;
    -webkit-text-decoration-style: none;
            text-decoration-style: none;
    caret-color: whitesmoke;
    color: white;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px;
    
    font-weight: 500;

    
}

.formInput::-webkit-input-placeholder {
    color: whitesmoke;
    opacity: 0.5;
    padding-left: 10px;
}

.formInput:-ms-input-placeholder {
    color: whitesmoke;
    opacity: 0.5;
    padding-left: 10px;
}

.formInput::placeholder {
    color: whitesmoke;
    opacity: 0.5;
    padding-left: 10px;
}

.formInput2{
    width: 100%;
    margin: 2%;
    padding-left: 10px;
    border:none;
    padding-right: 30px;
    text-decoration: none;
    border-style: none;
    background-color: transparent;
    border-bottom: 2px solid grey ;
    caret-color: whitesmoke;
    color:white;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    flex-grow: 2;
    margin-bottom: 20px;
    font-weight: 500;
}

.formButton{

    width: 40%;
    height: 45px;
    background-color: greenyellow;
    margin: 2%;
    border:none;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    border-style: none;
    cursor: pointer;

}
textarea:focus, input:focus{
    outline: none;
}
.responsiveContact{
    display: none;
}

/* Screen 1 */

@media screen  and (max-width: 920px) {

    .messageOuterContainer{
        width: 85%;
    }

    .messageText {
        display: none;
    }
    
    .messageForm {
        width: 100%;
        
    }
    .partitionLine {
        display: none;
    }
    .responsiveContact{
        display: block;
        font-family: "poppins" , "sans-serif";
        font-size: 2rem;
    }
    
}

/* Screen 2 */

@media screen  and (max-width: 600px) {

    .workInnerContainer{
        justify-content: start;
        padding-left: 5px;
    }

    .workContainerTitle {
        justify-content: start;
        padding-left: 50px;
        font-size: 50px;
    }
    #section1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150vh;
    }
    .section1-inner-container{
        width: 100%;
    }
}



@media screen  and (max-width: 360px) {

    .workInnerContainer{
        justify-content: start;
        padding-left: 5px;
    }

    .workContainerTitle {
        justify-content: start;
        padding-left:30px;
        font-size: 30px;
        margin-bottom: 0px;

    }
    .frontCard {

        font-size: 30px;
    }

    ion-icon {
        font-size: 75px;
        font-family: 'Shadows Into Light', cursive;
      }


}


.workItemContainer{
    width: 400px;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
    box-shadow: 10px 5px 5px black;
    border-radius: 2px;
    background-color:  rgba(255, 255, 255, 0.3);
    flex-shrink: 0;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.workItemContainer:last-child {
    margin-right: 30px;
}



.workItemContainer:hover {
    -webkit-transform: scale(105%);
            transform: scale(105%);
}

.workItemPicture{
    position: relative;


}
.workItemPicture img{
    width:100%;
    height:100%;
    object-fit:contain;
    position: relative;
    transition: -webkit-transform 300ms ease-in;
    transition: transform 300ms ease-in;
    transition: transform 300ms ease-in, -webkit-transform 300ms ease-in;
    cursor: pointer;
}


.workItemDescription{

    color: rgb(218, 216, 216);
    flex-grow: 1;
    line-height: 1.7em;
}

.workItemContainer h2 {
    margin: 15px 0px;   
    font-family: 'Poppins', sans-serif;
    color: var(--tertiary-color);
}

@media  screen and (max-width: 500px) {
    .workItemContainer{
        margin-left: 15px;
        width: 300px;
    }
    .workItemContainer:last-child {
        margin-right: 15px;
    }

    .workItemContainer:first-child {
        margin-left: 50px;
    }
    
    
}


@media screen  and (max-width: 360px) {

    .workItemContainer{
        margin-left: 15px;
        width: 300px;
    }
    .workItemContainer:last-child {
        margin-right: 20px;
    }

    .workItemContainer:first-child {
        margin-left: 20px;
    }

    .workItemDescription{
        font-size: 0.8em;
    }

    .workItemContainer h2{
        font-size: 0.9em;
        margin: 10px 0px;
    }
}
.message-chars-left {
    font-size: 0.8em;
    align-self:end;
    height: 0px;
}

.status-message {
    opacity: 0;
  }
  
.success {
    opacity: 1;
    color: var(--tertiary-color);
}

.failure {
    opacity: 1;
    color: rgb(238, 57, 57);

}

.closeButton{
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: -20px;
    right: 20px;
    cursor: pointer;
    font-size: 1rem;
    color: beige;

    


}

.hideButton {
    display: none;

}

.messageForm {
    position: relative;
}

@media screen  and (max-width: 360px) {
    
}

.form-container {
    /* From https://css.glass
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.8px);
    -webkit-backdrop-filter: blur(8.8px); */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    height: 100px;
}

.form-group button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.form-group button:hover {
    background-color: #0056b3;
}
html
{
    scroll-behavior: smooth;
}
.footer {
    /* background-color: white; */
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: azure;
    padding: 15px;
    position: relative;
}

.Icon-wrapper {
    display: flex;
    width: 150px;   
    /* background-color: red; */
    /* align-items: center; */
    justify-content: space-around;
    /* background-color: red; */


}

.Icon {
    list-style: none;
    display: flex;  
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    opacity: 0.9;

  
}

.footer p {
    font-family:'Poppins', Courier, monospace;
    font-size: 0.75em;
}

.Icon ion-icon {
    font-size: 25px;
    cursor: pointer;
    
   
}

.Icon:hover {
    opacity: 1;

    -webkit-transform: translateY(-3px);

            transform: translateY(-3px);
}






.spinner {

    background-color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    height: 100vh;
    font-family: 'ZCOOL KuaiLe', cursive;
    overflow: hidden;
    font-size: 2em;
    -webkit-animation: loadingTransition 1s ease-in 2s forwards;
            animation: loadingTransition 1s ease-in 2s forwards;

}

.spinner

.loader {
    width: 50%;
    height: 10px;
    background-color: white;
    margin-top: 2em;

}

.loader::after {
    content: "";
    width: 100%;
    height: 10px;
    background-color: var(--secondary-color);
    z-index: 10;
    -webkit-animation: loading 2s ease-in forwards;
            animation: loading 2s ease-in forwards;
    display: block;
}

@-webkit-keyframes loading {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
    
}

@keyframes loading {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
    
}

/* @keyframes loadingTransition {
    from {
        
         
    }
    to {
        transform: translateY(-100%);
    }
} */
