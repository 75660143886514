.message-chars-left {
    font-size: 0.8em;
    align-self:end;
    height: 0px;
}

.status-message {
    opacity: 0;
  }
  
.success {
    opacity: 1;
    color: var(--tertiary-color);
}

.failure {
    opacity: 1;
    color: rgb(238, 57, 57);

}

.closeButton{
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: -20px;
    right: 20px;
    cursor: pointer;
    font-size: 1rem;
    color: beige;

    


}

.hideButton {
    display: none;

}

.messageForm {
    position: relative;
}

@media screen  and (max-width: 360px) {
    
}

.form-container {
    /* From https://css.glass
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.8px);
    -webkit-backdrop-filter: blur(8.8px); */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    height: 100px;
}

.form-group button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.form-group button:hover {
    background-color: #0056b3;
}