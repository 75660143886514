@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.workItemContainer{
    width: 400px;
    transition: transform 1s ease;
    box-shadow: 10px 5px 5px black;
    border-radius: 2px;
    background-color:  rgba(255, 255, 255, 0.3);
    flex-shrink: 0;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.workItemContainer:last-child {
    margin-right: 30px;
}



.workItemContainer:hover {
    transform: scale(105%);
}

.workItemPicture{
    position: relative;


}
.workItemPicture img{
    width:100%;
    height:100%;
    object-fit:contain;
    position: relative;
    transition: transform 300ms ease-in;
    cursor: pointer;
}


.workItemDescription{

    color: rgb(218, 216, 216);
    flex-grow: 1;
    line-height: 1.7em;
}

.workItemContainer h2 {
    margin: 15px 0px;   
    font-family: 'Poppins', sans-serif;
    color: var(--tertiary-color);
}

@media  screen and (max-width: 500px) {
    .workItemContainer{
        margin-left: 15px;
        width: 300px;
    }
    .workItemContainer:last-child {
        margin-right: 15px;
    }

    .workItemContainer:first-child {
        margin-left: 50px;
    }
    
    
}


@media screen  and (max-width: 360px) {

    .workItemContainer{
        margin-left: 15px;
        width: 300px;
    }
    .workItemContainer:last-child {
        margin-right: 20px;
    }

    .workItemContainer:first-child {
        margin-left: 20px;
    }

    .workItemDescription{
        font-size: 0.8em;
    }

    .workItemContainer h2{
        font-size: 0.9em;
        margin: 10px 0px;
    }
}