body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

graphicsectionContainer {
  background-color: var(--secondary-color);
  z-index: 10;
  max-height: 400px;
}

.mainPage{
  animation: inflow 2s linear -1.5s;
}

/* @keyframes inflow {
  from {
    transform: translateX(+100%);

  }
  to {
    transform: translateX(0)
  }
} */